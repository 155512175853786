<template>
  <el-dialog :visible.sync="visible" append-to-body width="1100px" title="全部更换">
    <el-form inline size="small">
      <el-form-item label="老兵姓名:">
        <el-input v-model="inquireForm.name" size="small" />
      </el-form-item>
      <el-form-item label="番号:">
        <el-input v-model="inquireForm.number" size="small" />
      </el-form-item>
      <el-form-item label="阵亡时间:">
        <el-date-picker v-model="inquireForm.start" size="small" format="yyyy-MM-dd" />至
        <el-date-picker v-model="inquireForm.end" size="small" format="yyyy-MM-dd" />
      </el-form-item>
      <el-form-item label="有无照片:">
        <el-select v-model="haveImage" size="small">
          <el-option label="全部" value="" />
          <el-option label="有照片" value="1" />
          <el-option label="无照片" value="2" />
        </el-select>
      </el-form-item>
      <el-form-item label="资料完整:">
        <el-select v-model="haveData" size="small">
          <el-option label="全部" value="" />
          <el-option label="完整" value="1" />
          <el-option label="不完整" value="2" />
        </el-select>
      </el-form-item>
      <el-form-item label="老兵性别:">
        <el-select v-model="inquireForm.sex" size="small">
          <el-option label="全部" value="" />
          <el-option label="男" value="男" />
          <el-option label="女" value="女" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="getDataList">查 询</el-button>
        <el-button size="small" @click="rest">重 置</el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="dataListLoading"
      :data="veteranList"
      style="width: 100%; padding: 8px 0 ! important;"
      :header-cell-style="{background:'#F7F7F7',color:'#1E1E1E'}"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column label="老兵姓名" prop="name" />
      <el-table-column label="老兵番号" prop="number" />
      <el-table-column label="老兵籍贯" prop="hometown" />
      <el-table-column label="老兵编号" prop="id" />
      <el-table-column label="操作">
        <template slot-scope="{row}">
          <el-button type="text" @click="openDetail(row.id)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="current"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="size"
      :total="total"
      layout="total, sizes, prev, pager, next, jumper"
      style="padding:20px;"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="selectSubmit">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { veteranInfoList } from '@/utils/api.js'
export default {
  data() {
    return {
      visible: false,
      dataListLoading: false,
      inquireForm: {}, // 查询表单
      veteranList: [], // 全部更换查询的老兵列表
      current: 1, // 当前页
      size: 10, // 每页条数
      total: null, // 总条数
      selectedVeteran: [], // 被选中的老兵
      haveImage: '', // 是否有照片
      haveData: '' // 资料是否完整
    }
  },
  methods: {
    rest() {
      this.inquireForm = {}
      this.getDataList()
    },
    open() {
      this.visible = true
      this.getDataList()
    },
    // 获取老兵列表
    async getDataList() {
      this.dataListLoading = true
      const res = await veteranInfoList(this.inquireForm, this.current, this.size)
      this.dataListLoading = false
      if (res.code === 200) {
        this.veteranList = res.data.records
        this.total = res.data.total
      }
    },
    // 选好确认
    selectSubmit() {
      this.visible = false
      this.$emit('selected', this.selectedVeteran)
    },
    // 每页数
    handleSizeChange(val) {
      this.size = val
      this.current = 1
      this.getDataList()
    },
    // 当前页
    handleCurrentChange(val) {
      this.current = val
      this.getDataList()
    },
    handleSelectionChange(val) {
      this.selectedVeteran = val
    }
  }
}
</script>
<style lang="less">

</style>

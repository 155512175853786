<template>
  <div>
    <el-card>
      <div class="wall-header">
        <div class="button-wrapper">
          <el-button type="primary" @click="reRandom">重新随机</el-button>
          <el-button type="primary" @click="openSetting">
            英烈墙设置
          </el-button>
          <el-button type="primary" @click="openAdjust">批量调节</el-button>
        </div>
      </div>
      <div class="list-wrapper">
        <div v-for="item in tableList" :key="item.date" class="daily-item">
          <div class="dialy-header">
            <span style="margin-bottom: 5px">{{ item.date }}</span>
            <span>{{ item.week }}</span>
          </div>
          <div v-for="(it, index) in item.data" :key="index" class="veteran-item" @click="openVeteranDetail(it.veteranId)">{{ it.name }}</div>
        </div>
      </div>
    </el-card>
    <!-- 英烈墙设置 -->
    <el-dialog :visible.sync="setVisible" title="英烈墙设置" width="815px">
      <el-form label-width="80px">
        <el-form-item label="开启状态:">
          <el-radio-group v-model="setForm.open">
            <el-radio label="1">开启</el-radio>
            <el-radio label="0">关闭</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="展示数量:">
          <el-input v-model="setForm.showNumber" placeholder="请填写最大显示老兵的数量，只能填写大于0的整数" />
        </el-form-item>
        <el-form-item label="展示条件:">
          <div>
            <el-checkbox v-model="setForm.completeData" true-label="1" false-label="0">资料完整</el-checkbox>
            <div style="margin-top: -10px" />
            <el-checkbox v-model="setForm.hasAvatar" true-label="1" false-label="0">有老兵头像</el-checkbox>
            <div style="margin-top: -10px" />
            <el-checkbox v-model="setForm.hasPassAway" true-label="1" false-label="0">阵亡将士和病故老兵</el-checkbox>
            <br>
          </div>
          <div>如果没有选择展示条件则从所有老兵中随机选取老兵展示</div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="setVisible = false">取 消</el-button>
        <el-button type="primary" @click="setSubmit">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 批量调节 -->
    <batch-adjustment ref="batchAdjustment" @adjustSuccess="getDataList" />
    <!-- 老兵详情 -->
    <veteran-detail ref="veteranDetail" />
  </div>
</template>

<script>
import { veteranWallList, randomVeteranWall, getVeteranWallSetting, setVetaranWall } from '@/utils/api.js'
import BatchAdjustment from './components/BatchAdjustment'
import VeteranDetail from './components/VeteranDetail'
export default {
  components: { BatchAdjustment, VeteranDetail },
  data() {
    return {
      tableList: [],
      form: {},
      setVisible: false,
      setForm: {},
      status: '1'
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    async getDataList() {
      const res = await veteranWallList(this.form)
      if (res.code === 200) {
        this.tableList = res.data
        // this.tableList = res.data.sort((a, b) => {
        //   return a.date > b.date ? 1 : -1
        // })
      }
    },
    reRandom() {
      this.$confirm('是否确认重新随机?', '', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        randomVeteranWall(this.form).then(res => {
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '随机成功!'
            })
            this.getDataList()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    async openSetting() {
      const params = {}
      const res = await getVeteranWallSetting(params)
      if (res.code === 200) {
        const { open = false, showNumber, completeData, hasAvatar } = res.data || {}
        this.setForm = { open, showNumber, completeData, hasAvatar }
      }
      this.setVisible = true
    },
    async setSubmit() {
      this.setVisible = false
      this.setForm.showNumber -= 0
      const res = await setVetaranWall(this.setForm)
      if (res.code === 200) {
        this.$message({
          message: '设置成功',
          type: 'success'
        })
        this.getDataList()
      } else {
        this.$message.error(res.message)
      }
    },
    openAdjust() {
      this.$refs.batchAdjustment.open()
    },
    openVeteranDetail(id) {
      this.$refs.veteranDetail.open(id)
    }
  }
}
</script>

<style lang="less" scoped>
.wall-header {
  .button-wrapper {
    float: right;
    margin-bottom: 20px;
  }
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
.el-input {
  width: 50%;
}
.list-wrapper {
  width: 100%;
  display: flex;
  border: 1px solid #D5D5D5;
  .daily-item {
    width: (1/15)*100%;
    .dialy-header {
      width: 100%;
      height: 70px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #D9DDE2;
      color: #1E1E1E;
      font-size: 16px;
    }
    .veteran-item {
      width: 100%;
      height: 60px;
      line-height: 60px;
      text-align: center;
      border-bottom: 1px solid #D5D5D5;
      font-size: 14px;
      color: #333333;
      cursor: pointer;
    }
  }
}
</style>

<template>
  <div>
    <el-dialog :visible.sync="adjustVisible" :close-on-click-modal="false" :title="adjustTitle" width="1200px">
      <el-form v-if="allChangeState===1" inline>
        <el-form-item label="选择时间">
          <el-date-picker v-model="form.start" size="small" value-format="yyyy-MM-dd" />至
          <el-date-picker v-model="form.end" size="small" value-format="yyyy-MM-dd" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getListByDate">查询</el-button>
          <el-button type="primary" size="small" @click="allChangeState=2; adjustTitle='全部更换'">全部更换</el-button>
        </el-form-item>
      </el-form>
      <div v-else-if="allChangeState===2" class="all-change-box">
        <span>已选择老兵3个，最多可选择40个</span>
        <el-button type="primary" size="small" @click="openAllChange">继续添加</el-button>
      </div>
      <!-- 全部更换里添加老兵 -->
      <all-change ref="allChange" @selected="finishSelect" />
      <el-table
        v-loading="dataListLoading"
        :data="tableData"
        style="width: 100%; padding: 8px 0 ! important;"
        :header-cell-style="{background:'#F7F7F7',color:'#1E1E1E'}"
      >
        <el-table-column label="老兵姓名" prop="name" />
        <el-table-column label="显示顺序" type="index" width="200px" />
        <el-table-column label="老兵编号" prop="veteranId" />
        <el-table-column label="操作">
          <template slot-scope="{row}">
            <el-button type="text" @click="openChange(row)">更换</el-button>
            <el-button v-if="allChangeState===2" type="text" @click="moveOut(row)">移出</el-button>
            <el-button type="text" @click="openAdjustOrder(row)">调整顺序</el-button>
            <el-button type="text" @click="openDetail(row.veteranId)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 更换 -->
      <el-dialog
        title="更换"
        width="35%"
        :visible.sync="changeVisible"
        append-to-body
      >
        <div class="marginbot-box">请选择要更换成哪位英雄</div>
        <el-input v-model="veteranName" placeholder="请输入英雄名称" />
        <el-button type="primary" @click="getVeteranList">查询</el-button>
        <el-radio-group v-model="selectedVeteran">
          <div v-for="value in veteranListByName" :key="value.id" class="margintop-box">
            <el-radio :label="value">{{ value.name }} - {{ value.id }}</el-radio>
            <el-button type="text" size="small" @click="openDetail(value.id)">详情</el-button>
          </div>
        </el-radio-group>
        <div slot="footer" class="dialog-footer">
          <el-button @click="changeVisible = false">取 消</el-button>
          <el-button type="primary" @click="changeSubmit">确 定</el-button>
        </div>
      </el-dialog>
      <!-- 调整顺序 -->
      <el-dialog
        :visible.sync="adjustOrderVisible"
        title="调整顺序"
        append-to-body
        width="800px"
      >
        <el-form inline>
          <el-form-item label="显示顺序:">
            <el-input v-model="nowOrder" size="small" />
          </el-form-item>
          <el-form-item>
            <span style="color: #999999">显示顺序最大不超过40（可在英烈墙设置中设置展示英雄数量）</span>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="adjustOrderVisible = false">取 消</el-button>
          <el-button type="primary" @click="adjustOrder">确 定</el-button>
        </div>
      </el-dialog>

      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
      <!-- 详情 -->
      <veteran-detail ref="veteranDetail" />
    </el-dialog>
  </div>
</template>

<script>
import { listByDate, updateByDate, veteranInfoList } from '@/utils/api.js'
import VeteranDetail from './VeteranDetail'
import AllChange from './AllChange'
export default {
  components: { VeteranDetail, AllChange },
  data() {
    return {
      tableData: [],
      form: {
        start: '',
        end: ''
      },
      adjustTitle: '批量调节',
      adjustVisible: false, // 批量调节
      dataListLoading: false,
      changeVisible: false, // 更换
      adjustOrderVisible: false, // 调整顺序
      allChangeState: 1, // 全部更换状态 1.批量调节  2.全部更换
      pastOrder: 0, // 过去的顺序
      nowOrder: null, // 调整的顺序
      veteranName: '',
      veteranListByName: [],
      selectedVeteran: {},
      changeIndex: null // 将要更换的老兵索引
    }
  },
  created() {
    const tormower = new Date()
    tormower.setTime(tormower.getTime() + 24 * 60 * 60 * 1000)
    this.form.start = tormower
    this.form.end = tormower
  },
  methods: {
    open() {
      this.allChangeState = 1
      this.adjustVisible = true
    },
    async getListByDate() {
      if (!this.form.start || !this.form.end) {
        this.$message.error('请选择时间')
        return
      }
      const res = await listByDate(this.form)
      if (res.code === 200) {
        this.tableData = res.data[0].data
      }
    },
    openDetail(id) {
      this.$refs.veteranDetail.open(id)
    },
    // 继续添加
    openAllChange() {
      this.$refs.allChange.open()
    },
    // 打开更换
    openChange(row) {
      this.changeIndex = this.tableData.indexOf(row)
      this.changeVisible = true
    },
    // 更换
    changeSubmit() {
      this.tableData.splice(this.changeIndex, 1, this.selectedVeteran)
      this.changeVisible = false
    },
    // 打开调整顺序
    openAdjustOrder(row) {
      this.pastOrder = this.tableData.indexOf(row)
      this.adjustOrderVisible = true
    },
    // 调整顺序
    adjustOrder() {
      const pastOrder = this.pastOrder
      const nowOrder = this.nowOrder - 1
      console.log(pastOrder, nowOrder)
      this.tableData[pastOrder] = this.tableData.splice(nowOrder, 1, this.tableData[pastOrder])[0]
      this.adjustOrderVisible = false
    },
    // 移出
    moveOut(row) {
      const index = this.tableData.indexOf(row)
      this.tableData.splice(index, 1)
    },
    async submit() {
      if (this.allChangeState === 1) {
        if (!this.form.start || !this.form.end) {
          this.$message.error('请选择时间')
          return
        }
        this.adjustTitle = '批量调节'
        const veteranInfos = []
        let id
        this.tableData.forEach((value) => {
          id = value.veteranId ? value.veteranId : value.id
          veteranInfos.push({ id })
        })
        const setForm = {}
        setForm.veteranInfos = veteranInfos
        setForm.start = this.form.start
        setForm.end = this.form.end
        const res = await updateByDate(setForm)
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '批量调节成功'
          })
          this.adjustVisible = false
          this.$emit('adjustSuccess')
        } else {
          this.$message.error(res.message)
        }
      } else {
        this.allChangeState = 1
      }
    },
    cancel() {
      this.allChangeState !== 1 ? --this.allChangeState : this.adjustVisible = false
      if (this.allChangeState === 1) {
        this.adjustTitle = '批量调节'
      }
    },
    // 更换里根据姓名查询老兵列表
    async getVeteranList() {
      if (!this.veteranName) {
        this.$message.error('请输入老兵姓名')
        return
      }
      const res = await veteranInfoList({ name: this.veteranName }, 1, 100)
      if (res.code === 200) {
        this.veteranListByName = res.data.records
      }
    },
    // 选择完成
    finishSelect(val) {
      this.tableData.push(...val)
    }
  }
}
</script>

<style>
.marginbot-box {
  margin-bottom: 10px;
}
.margintop-box {
  margin-top: 5px;
}
.el-input {
  width: 75%;
  margin-right: 10px;
}
.all-change-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0 5px;
}
</style>
